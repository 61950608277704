/* eslint-disable global-require */
import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import { Cell, Tabbar, TabbarItem } from 'vant'
import { Dialog } from 'vant'
import { Popup } from 'vant'

Vue.use(Popup)
Vue.use(Cell)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Dialog)

export default {
  components: {},
  data () {
    return {
      shenfenzheng: " ",
      statu: " ",
      Cname: false,
      name: "",
      userFirmname: '',//身份证姓名
      userIdCard: '',//身份证号
      // shenfen: false,
      active: 0,
      icon: {
        active: require('../../assets/image/xuanzhong.png'),
        inactive: require('../../assets/image/sy.png'),
      },
      icon1: {
        active: require('../../assets/image/wd.png'),
        inactive: require('../../assets/image/meixuanzhong.png'),
      },
      integral: 0,
      warOder: 0,
      Order: 0,
      islogin: false,
      userName: '',
      userPhone: '',
      userImage: require("../../assets/image/avator.png"),
      show1: false,
      show: false,
      // show1: true,
      // show: false,
    }
  },
  watch: {
    $route () {
      // 在mounted函数执行的方法，放到该处
      if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
        this.islogin = true
        this.myInfo().then(res => {
          if (res.code == 200) {
            if (res.accountBanlance != '' && res.accountBanlance != null) {
              this.integral = res.accountBanlance
            }

            this.warOder = res.bxOrderNums
            this.Order = res.flOrderNums
            this.userName = res.user.userName
            this.userPhone = res.user.userPhone
            if (res.user.userCompanyName == null || res.user.userCompanyName == "") {
              this.Cname = false

            } else {
              this.Cname = true
              this.name = res.user.userCompanyName
            }

            localStorage.setItem('userName', res.user.userName)
            localStorage.setItem('userPhone', res.user.userPhone)
            if (res.user.userImage != '' && res.user.userImage != null) {
              this.userImage = res.user.userImage
              localStorage.setItem('userImage', res.user.userImage)
            }
            if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
              this.islogin = true
            } else {
              this.islogin = false
            }


          } else {

            if (res.code == 504) {
              localStorage.removeItem('token')
            } else {
              this.islogin = false
              this.userImage = require("../../assets/image/avator.png")
              this.integral = 0
              this.warOder = 0
              this.Order = 0
            }
          }

        })
      } else {
        this.islogin = false
        this.userImage = require("../../assets/image/avator.png")
        this.integral = 0
        this.warOder = 0
        this.Order = 0
      }
    }
  },

  created () {
    this.getName()
    setTimeout(() => {
      this.getShen()
    }, 100)

    if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
      this.islogin = true
      this.myInfo().then(res => {
        if (res.code == 200) {

          if (res.accountBanlance != '' && res.accountBanlance != null) {
            this.integral = res.accountBanlance
          }
          this.warOder = res.bxOrderNums
          this.Order = res.flOrderNums
          this.userName = res.user.userName
          this.userPhone = res.user.userPhone
          if (res.user.userCompanyName == null || res.user.userCompanyName == "") {
            this.Cname = false

          } else {
            this.Cname = true
            this.name = res.user.userCompanyName
          }
          localStorage.setItem('userName', res.user.userName)
          localStorage.setItem('userPhone', res.user.userPhone)
          if (res.user.userImage != '' && res.user.userImage != null) {
            this.userImage = res.user.userImage
            localStorage.setItem('userImage', res.user.userImage)
          }
          if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
            this.islogin = true
          } else {
            this.islogin = false
          }


        } else {
          if (res.code == 504) {
            localStorage.removeItem('token')
            this.islogin = false
            Dialog.confirm({
              title: '系统提示',
              message: res.msg,
              confirmButtonText: '确定',
              confirmButtonColor: "#5d6d8e",
              showCancelButton: false,
              className: 'tishi'
            }).then(() => {


            })

          } else {
            this.islogin = false
            this.userImage = require("../../assets/image/avator.png")
            this.integral = 0
            this.warOder = 0
            this.Order = 0
          }
        }

      })
    } else {
      this.islogin = false
    }
  },
  methods: {
    ...mapActions('setup', ['myInfo']),
    ...mapActions('home', ['smrzInfo', 'checkCardOverdue', 'notReminder']),
    getName () {
      if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
        this.smrzInfo().then((res) => {
          this.userFirmname = res.data.userFirmname

        })
      }

    },
    getShen () {
      var data = JSON.stringify({
        sign: 1
      })
      this.checkCardOverdue(data).then((res) => {
        if (res.flag == 0) {
          this.statu = "已过期"
          this.shenfenzheng = "shenfenzheng"
          // this.$router.push({ path: '/card' })
        } else if (res.flag == 1) {

          this.statu = "已实名"
          this.shenfenzheng = "shenfenzheng1"
          // this.$router.push({ name: 'identity' })
        } else if (res.flag == 2) {
          this.shenfenzheng = "shenfenzheng"
          this.statu = "未实名"
          // this.$router.push({ path: '/card' })
        }
      })
    },

    goLogin () {
      this.$router.push({ path: '/login' })
    },
    goRegister () {
      this.$router.push({ path: '/cregister' })
    },
    toserve () {
      if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
        this.$router.push({ name: 'equityCenter' })
      } else {
        Dialog.confirm({
          title: '系统提示',
          message: '请重新登录!',
          confirmButtonText: '确定',
          confirmButtonColor: "#5d6d8e",
          showCancelButton: false,
          className: 'tishi'
        }).then(() => {
          this.$router.push({
            name: 'Login'
          })

        })
      }
      // Dialog.alert({
      //   confirmButtonText: '确定',
      //   confirmButtonColor: '#5d6d8e',
      //   showCancelButton: false,
      //   className: 'tishi',
      //   title: '系统提示',
      //   message: '此功能尚未开放!',
      // })
    },
    toCard () {

      if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
        var data = JSON.stringify({
          sign: 1
        })
        this.checkCardOverdue(data).then((res) => {
          if (res.flag == 0) {
            this.statu = "已过期"
            this.shenfenzheng = "shenfenzheng"
            this.$router.push({ path: '/card', query: { card: 2 } })
          } else if (res.flag == 1) {

            this.statu = "已实名"
            this.shenfenzheng = "shenfenzheng1"
            this.$router.push({ path: '/identity' })
          } else if (res.flag == 2) {
            this.shenfenzheng = "shenfenzheng"
            this.statu = "未实名"
            this.$router.push({ path: '/card', query: { card: 2 } })
          }
        })
      } else {
        Dialog.confirm({
          title: '系统提示',
          message: '请重新登录!',
          confirmButtonText: '确定',
          confirmButtonColor: "#5d6d8e",
          showCancelButton: false,
          className: 'tishi'
        }).then(() => {
          this.$router.push({
            name: 'Login'
          })

        })
      }


    },
    tosetup () {
      if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
        this.$router.push({ name: 'Setup' })
      } else {
        Dialog.confirm({
          title: '系统提示',
          message: '请登录后操作!',
          confirmButtonText: '确定',
          confirmButtonColor: "#5d6d8e",
          showCancelButton: false,
          className: 'tishi'
        }).then(() => {
          this.$router.push({
            name: 'Login'
          })

        })
      }

    },
    toUpload () {
      this.$router.push({ path: '/card', query: { card: 1 } })
    },
    toWarranty () {
      //console.log(1111, localStorage.getItem('token'))
      if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
        var data = JSON.stringify({
          sign: 1
        })
        this.checkCardOverdue(data).then((res) => {
          if (res.flag == 0) {

            this.show1 = true
            this.show = false
            // this.$router.push({ path: '/card' })

          } else if (res.flag == 1) {

            this.show1 = false
            this.show = false
            this.$router.push({ name: 'Warranty2', query: { jump: 0 } })
          } else if (res.flag == 2) {
            this.show1 = false
            this.show = true

          }
        })

      } else {
        Dialog.confirm({
          title: '系统提示',
          message: '请登录后操作!',
          confirmButtonText: '确定',
          confirmButtonColor: "#5d6d8e",
          showCancelButton: false,
          className: 'tishi'
        }).then(() => {
          this.$router.push({
            name: 'Login'
          })

        })
      }

    },
    toOrder () {
      // this.noOpen();
      if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
        this.$router.push({ name: 'myOrder' })
      } else {
        Dialog.confirm({
          title: '系统提示',
          message: '请重新登录!',
          confirmButtonText: '确定',
          confirmButtonColor: "#5d6d8e",
          showCancelButton: false,
          className: 'tishi'
        }).then(() => {
          this.$router.push({
            name: 'Login'
          })

        })
      }
    },

    toAddress () {
      // this.noOpen();
      if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
        this.$router.push({ name: 'addressList', query: { back: 1, ismine: true } })
      } else {
        Dialog.confirm({
          title: '系统提示',
          message: '请重新登录!',
          confirmButtonText: '确定',
          confirmButtonColor: "#5d6d8e",
          showCancelButton: false,
          className: 'tishi'
        }).then(() => {
          this.$router.push({
            name: 'Login'
          })

        })
      }
    },
    noOpen () {
      Dialog.alert({
        confirmButtonText: '确定',
        confirmButtonColor: "#5d6d8e",
        showCancelButton: false,
        className: 'tishi',
        title: '系统提示',
        message: '此功能尚未开放!',
      })

    }
  },
}
